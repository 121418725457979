const comments_section = `Comments section here.
    It's also a link recognizer.
    https://www.grabthesnippet.com/`;

const data1 = {
    title: "Welcome Javascript",
    language: "javascript",
    code: `// with ctrl+/ you can add comments
    console.log("Hello World")`,
    comments: comments_section,
  };
  const data2 = {
    title: "Welcome PHP",
    language: "php",
    code: `<!-- with ctrl+/ you can add comments -->
    <?php 
    echo "Hello World"
    ?>`,
    comments: comments_section,
  };

  const data3 = {
    title: "Welcome Python",
    language: "python",
    code: `# with ctrl+/ you can add comments
    printf("Hello World")`,
    comments: comments_section,
  };

  export const example_of_code_for_home_page = [ data1, data2, data3 ];