
import { useState, useEffect } from 'react';

export const returnToTop = function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
export default function useScroll() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setScrolled(false);
    };
    return [scrolled, goToTop];
}
