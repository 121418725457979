import configureStore from "../redux/store"
import { clearUserAction ,setUserLandedAction,setLoggedInAction,openCardModalAction} from "../redux/actions";
import {store} from "../index"
const apiUrl = process.env.REACT_APP_BE_URL;

function handleRequestOptions(method) {
  return {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
}

async function refreshToken() {
  try {
    const response = await myFetch(`${apiUrl}/users/refreshToken`, "POST");
    if (response.status === 401) {
      throw new Error(`Token Expired`);
    }
  } catch (error) {
    console.error( error);
    store.dispatch(setLoggedInAction())
    store.dispatch(setUserLandedAction(false));
    localStorage.setItem("userLanded", false)
   
  }
}
async function myFetch (url,method) {
  const response = await fetch(url, handleRequestOptions(method));
  return response
}

export async function handleApiRequest(path, method) {
  try {
  store.dispatch(clearUserAction())
  store.dispatch(setUserLandedAction(false));
  localStorage.setItem("userLanded", false)
    const url = apiUrl + "/" + path
    let response = await myFetch(url, method);
  
    if (response.status === 401) {
      await refreshToken();
      response = await myFetch(url, method);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error handling API request:', error);
    throw error; // Propagate the error for better error handling in the calling code
  }
}
