import {  AiOutlineArrowUp } from "react-icons/ai";
import { IconContext } from "react-icons"; // this is so i can style the react icon
import useScroll from "../../lib/scroll";

const ScrollButton = () => {
    const [ scrolled, goToTop ] = useScroll();
    if (!scrolled) return null
    return (
        
        <div className="goToTopBtn" onClick={goToTop}>
        <IconContext.Provider value={{ className: "arrow" }}>
          <AiOutlineArrowUp />
        </IconContext.Provider>
      </div>
    )
}

export default ScrollButton